<script>
  import { fade, slide } from 'svelte/transition';
  import * as animateScroll from 'svelte-scrollto';

  import IntersectionObserver from './IntersectionObserver.svelte';
  import filter from '../functions/filter.js';
  import ListItem from './ListItem.svelte';
  import Button from './Button.svelte';
  import Filter from './Filter.svelte';
  import Tabs from './Tabs.svelte';
  import LoadingDots from './LoadingDots.svelte';
  import FilteredByText from './FilteredByText.svelte';

  export let params = false;

  let options = false;
  let providers = false;
  let visible = true;
  let initial = true;
  let filterInView = false;

  animateScroll.setGlobalOptions({
    offset: 100,
  });

  fetch('https://' + __API_URL__ + '/json/options')
    .then((response) => response.json())
    .then((data) => (options = data));
  fetch('https://' + __API_URL__ + '/json/providers')
    .then((response) => response.json())
    .then((data) => (providers = data));

  const ages = [
    [0, 'Deselect All', [false, false]],
    [1, 'All Ages with a Disability', [0, Infinity]],
    [2, 'Age 1 to 5', [1, 5]],
    [3, 'Age 6 to 12', [6, 12]],
    [4, 'Age 13 to 17', [13, 17]],
    [5, 'Age 18 to 54', [18, 54]],
    [6, 'Age 55 to 59', [55, 59]],
    [7, 'Age 60 and above', [60, Infinity]],
  ];

  const initialChecked = {
    tab: 'all', // all|call_ahead|just_show_up
    term: '',
    day: false, // day of the week Mo, Tu...
    today: false,
    serviceRegionOptions: [],
    serviceCitiesOptions: [],
    vehicleTypeOptions: [],
    accesibilityOptions: [],
    age: [],
    medical_trip: true,
    paratransit_eligibility: true,
    veterans_eligibility: true,
    medicaid_eligibility: true,
  };
  let checked = {
    ...initialChecked,
  };
  if (params.filter && params.filter !== 'stored') {
    let urlFilter = params.filter.split('|');
    urlFilter = urlFilter
      .map((el) => el.split('='))
      .reduce((obj, el) => {
        return { ...obj, [el[0]]: parseInt(el[1]) };
      }, {});
    localStorage.removeItem('checked');
    checked = { ...initialChecked };
    // Change filter from url parameters
    checked.serviceRegionOptions = urlFilter.serviceRegionOptions
      ? [urlFilter.serviceRegionOptions]
      : [];
    checked.accesibilityOptions = urlFilter.accesibilityOptions
      ? [urlFilter.accesibilityOptions]
      : [];
    checked.medical_trip = Boolean(urlFilter.medical_trip) || false;
  } 
  else if (params.filter && params.filter === 'stored' && localStorage.getItem('checked')) {
    checked = JSON.parse(localStorage.getItem('checked'));
  }
  else {
    localStorage.removeItem('checked');
  }

  let ids = [];

  // Save filters to localstorage
  $: if (JSON.stringify(checked) !== JSON.stringify(initialChecked)) {
    localStorage.setItem('checked', JSON.stringify(checked));
    history.replaceState(checked, "Providers", "#/providers/stored");
  } else {
    localStorage.removeItem('checked');
  }
  $: if (providers && options) {
    // Make Filter
    if (initial) {
      // No delay first display
      ids = filter({ providers, checked, initialChecked, ages });
      initial = false;
    } else {
      // if (visible === false) // still loading
      visible = false; // Loading should be shown

      window.setTimeout(() => {
        ids = filter({ providers, checked, initialChecked, ages });
        visible = true;
      }, 1 * 1000);
    }
  }

  $: items = providers
    ? ids.reduce((arr, id) => [...arr, providers[id]], [])
    : [];

  $: {
    // console.log(providers);
    // console.log(items);
    // console.log(checked);
  }
</script>

<style>
  .providers {
    /* min-height: 900px; */
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
  }
  @media (min-width: 768px) {
    .providers {
      margin-bottom: 0;
    }
  }
  .filterwrap {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 768px) {
    .providers {
      flex-direction: row;
    }
    .filterwrap {
      max-width: 25%;
      /* flex-grow: 1; */
    }
    .wrapper {
      flex-grow: 1;
    }
  }
  .summaryline {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 10px;
    align-items: center;
  }
  .clearfilter {
    font-weight: bold;
    display: flex;
  }
  .found {
    font-weight: bold;
    padding-left: 15px;
  }
  .items {
  }
  .hideMobile {
    display: none;
  }
  @media (min-width: 768px) {
    .hideMobile {
      display: block;
    }
  }
  .showMobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 768px) {
    .showMobile {
      display: none;
    }
  }
  .mobileLoading {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #f0d4b2;
    height: 100px;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px #333;
    border-top: 1px #222;
  }
  @media (min-width: 768px) {
    .mobileLoading {
      display: none;
    }
  }
</style>

{#if filterInView}
  <div class="mobileLoading" in:slide out:slide>
    {#if visible}
      <Button
        on:mousedown={(e) => {
          animateScroll.scrollTo({
            element: '#providers_results',
            offset: -70,
            duration: 500,
          });
        }}>
        &darr; View Results &darr;
      </Button>
    {:else}
      <LoadingDots />
    {/if}
  </div>
{/if}
<Tabs bind:tab={checked.tab} />
<div class="providers">
  <div class="filterwrap">
    <IntersectionObserver
      bind:shown={filterInView}
      let:intersecting
      bottom={-600}>
      <Filter {options} {ages} bind:checked />
    </IntersectionObserver>
  </div>
  <div class="wrapper">
    {#if providers && options}
      {#if visible}
        <div class="summaryline">
          <div class="clearfilter">
            {#if JSON.stringify(checked) !== JSON.stringify(initialChecked)}
              {#if !items.length}
                <div style="padding-right: 15px;">
                  <FilteredByText {ages} {options} {checked} {initialChecked} />
                </div>
              {/if}
              <Button
                on:mousedown={(e) => {
                  checked = { ...initialChecked };
                  localStorage.removeItem('checked');
                  animateScroll.scrollTo({
                    element: '#providers',
                    offset: -100,
                    duration: 500,
                  });
                }}>
                Clear Filter
              </Button>
            {/if}
          </div>
          <div class="found">
            {#if visible}
              {items.length ? `${items.length} provider${items.length > 1 ? 's' : ''}` : 'Nothing found. Please change the filters and try again.'}
            {/if}
          </div>
        </div>
      {/if}
      {#if visible}
        <div class="items" in:fade id="providers_results">
          {#each items as item, i}
            <ListItem {item} {options} odd={i % 2} />
          {/each}
        </div>
      {:else}
        <div style="padding: 50px; text-align: center;" id="providers_results">
          <LoadingDots />
        </div>
      {/if}
      {#if items.length && visible}
        <div class="summaryline">
          <div class="clearfilter">
            {#if JSON.stringify(checked) !== JSON.stringify(initialChecked)}
              <FilteredByText {ages} {options} {checked} {initialChecked} />
              <Button
                on:mousedown={(e) => {
                  checked = { ...initialChecked };
                  localStorage.removeItem('checked');
                  animateScroll.scrollTo('#providers_results');
                }}>
                Clear Filter
              </Button>
            {:else}
              <div class="showMobile">
                <Button
                  on:mousedown={(e) => {
                    animateScroll.scrollTo('#providers_results');
                  }}>
                  &uarr; To Filters &uarr;
                </Button>
              </div>
            {/if}
          </div>
          <div class="found hideMobile">
            {#if visible}
              {items.length ? `${items.length} provider${items.length > 1 ? 's' : ''}` : 'Nothing found. Please change the filters and try again.'}
            {/if}
          </div>
        </div>
      {/if}
    {:else}
      <ListItem odd={0} />
      <ListItem odd={1} />
      <ListItem odd={0} />
    {/if}
  </div>
</div>
