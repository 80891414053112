<script>
  import { fade } from 'svelte/transition';
  import collection from '../geojson.json';
  let zoomed = false;
  export let regions = [];
  let map;
  function createMap(container) {
    let m = L.map(container, {
      minZoom: 5,
      maxZoom: 13,
      scrollWheelZoom: false,
    }).setView([47.52091047852614, -121.65223999023436], 7);
    L.tileLayer(
      'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
      {
        attribution: `&copy;<a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>,
        &copy;<a href="https://carto.com/attributions" target="_blank">CARTO</a>`,
        subdomains: 'abcd',
        maxZoom: 13,
      }
    ).addTo(m);
    L.geoJson(collection, {
      style: {
        weight: 2,
        color: '#DE7E0A',
      },
      filter: (f, l) => {
        return regions.includes(f.properties.id);
      },
    }).addTo(m);
    return m;
  }
  function mapAction(container) {
    if (typeof L === 'undefined' || !L) {
      return { destroy: () => {} };
    } else {
      map = createMap(container);
      return {
        destroy: () => {
          if (map && map.remove) {
            map.remove();
            map = null;
          }
        },
      };
    }
  }
</script>

<style>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10000;
  }
  .close {
    position: fixed;
    z-index: 1000;
    top: 5vh;
    right: 5vw;
    color: white;
    font-size: 3em;
    cursor: pointer;
    z-index: 10000;
  }

  .overlay .img {
    height: 80vh;
    width: 80vw;
    margin: auto;
    /* display: flex; */
    z-index: 10000;
  }
  .minimap {
    height: 350px;
    width: 100%;
  }
  .bigmap {
    height: 100%;
    width: 100%;
    z-index: 10000;
  }
</style>

{#if zoomed}
  <div
    class="overlay"
    title="Click to Close"
    transition:fade={{ duration: 200 }}>
    <div class="img">
      <div class="bigmap" use:mapAction />
    </div>
  </div>
  <div
    class="close"
    transition:fade={{ duration: 200 }}
    on:click={() => (zoomed = false)}>
    &#10005;
  </div>
{/if}
<div class="minimap" on:click={() => (zoomed = true)} use:mapAction />
