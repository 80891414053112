<script>
  import {
    DollarSignIcon,
    UserIcon,
    WatchIcon,
    CrosshairIcon,
    PhoneCallIcon,
    BookmarkIcon,
  } from 'svelte-feather-icons';
  import { fade } from 'svelte/transition';
  import * as animateScroll from 'svelte-scrollto';
  import Rating from './Rating.svelte';

  export let item = false;
  export let odd = 0;
  export let options = false;

  const callAhead = ['"Just Show Up" Service', 'Prescheduled Service'];

  let service_days = false;
  let accessibility = false;

  $: if (item && options) {
    service_days = item.service_days
      .split(',')
      .map((i) => options.serviceDaysOptions[i])
      .join(', ');
    accessibility = item.accessibility
      .split(',')
      .map((i) => options.accesibilityOptions[i])
      .join(', ');
  }

  $: url = `#/provider/${item.alias}`;
</script>

<style>
  .item {
    padding: 15px 15px 35px 15px;
    background: rgba(237, 240, 235, 0.822);
  }
  .odd {
    background: transparent;
  }
  .main {
    display: flex;
    flex-wrap: wrap;
  }

  .details {
    width: 100%;
  }
  .contacts {
    font-size: 1.2em;
    width: 100%;
    line-height: 1.44em;
  }
  @media (min-width: 600px) {
    .main {
      flex-wrap: nowrap;
    }
    .details {
      width: 75%;
    }
    .contacts {
      width: 25%;
    }
  }

  a {
    color: #de7e0b;
    text-decoration: none;
    transition: color 0.2s;
  }
  a:hover {
    color: #ca6f00;
  }
  .title {
    margin: 10px 0;
  }
  .title a {
    font-size: 1.6em;
    font-weight: 500;
    line-height: 1.92em;
  }
  .line {
    font-size: 1em;
    display: flex;
    margin: 10px 0;
    line-height: 1.2em;
  }
  .line .icon {
  }
  .line .icon .pic {
    width: 50px;
    text-align: center;
  }
  .line .text {
    font-size: 1.1em;
    flex-grow: 1;
    width: auto;
    padding-right: 10px;
    padding-top: 5px;
  }
  .more-info {
    display: inline-block;
    padding: 10px 0;
  }
  .placeholder {
    width: 100%;
    padding: 5px 0;
    background: linear-gradient(270deg, #d2d4d4, #a6aaaa);
    background-size: 400% 400%;

    -webkit-animation: placeholderAnimation 2s ease infinite;
    -moz-animation: placeholderAnimation 2s ease infinite;
    animation: placeholderAnimation 2s ease infinite;
  }
</style>

{#if item}
  <div class="item" in:fade class:odd>
    <div class="title">
      <div style="float:right;">
        <Rating id={item.id} number={item.rating} />
      </div>
      <a
        on:click={() => {
          animateScroll.scrollTo({
            element: '#providers',
            offset: -100,
            duration: 500,
          });
        }}
        href={url}>{item.name}</a>
    </div>
    <div class="main">
      <div class="details">
        <div class="line">
          <div class="icon">
            <div class="pic">
              {#if item.call_ahead}
                <PhoneCallIcon size="1.5x" />
              {:else}
                <CrosshairIcon size="1.5x" />
              {/if}
            </div>
          </div>
          <div class="text">{callAhead[item.call_ahead]}</div>
        </div>
        <div class="line">
          <div class="icon">
            <div class="pic">
              <WatchIcon size="1.5x" />
            </div>
          </div>
          <div class="text">
            {service_days}
            <strong>{item.service_hours}</strong>
          </div>
        </div>
        <div class="line">
          <div class="icon">
            <div class="pic">
              <UserIcon size="1.5x" />
            </div>
          </div>
          <div class="text">{accessibility}</div>
        </div>
        {#if item.cost_adult}
          <div class="line">
            <div class="icon">
              <div class="pic">
                <DollarSignIcon size="1.5x" />
              </div>
            </div>
            <div class="text">
              {item.cost_adult}
              <strong>for Adults</strong>
            </div>
          </div>
        {/if}
        {#if item.available_discounts}
          <div class="line">
            <div class="icon">
              <div class="pic">
                <BookmarkIcon size="1.5x" />
              </div>
            </div>
            <div class="text">
              <a
                on:click={() => {
                  animateScroll.scrollTo({
                    element: '#providers',
                    offset: -100,
                    duration: 500,
                  });
                }}
                href={url}>Full Fare Information &rarr;</a>
            </div>
          </div>
        {/if}
      </div>
      <div class="contacts">
        {@html item.contacts.replace(/\n/g, '<br />')}<br />
        <a
          on:click={() => {
            animateScroll.scrollTo({
              element: '#providers',
              offset: -100,
              duration: 500,
            });
          }}
          class="more-info"
          href={url}>More Info &rarr;</a>
      </div>
    </div>
  </div>
{:else}
  <div class="item" class:odd>
    <div class="title">
      <div class="placeholder">&nbsp;</div>
    </div>
    <div class="main">
      <div class="details">
        <div class="line">
          <div class="icon">
            <div class="pic" style="opacity:0.5">
              <PhoneCallIcon size="1.5x" />
            </div>
          </div>
          <div class="text">
            <div class="placeholder">&nbsp;</div>
          </div>
        </div>
        <div class="line">
          <div class="icon">
            <div class="pic" style="opacity:0.5">
              <WatchIcon size="1.5x" />
            </div>
          </div>
          <div class="text">
            <div class="placeholder">&nbsp;</div>
          </div>
        </div>
        <div class="line">
          <div class="icon">
            <div class="pic" style="opacity:0.5">
              <UserIcon size="1.5x" />
            </div>
          </div>
          <div class="text">
            <div class="placeholder">&nbsp;</div>
          </div>
        </div>
        <!-- <div class="line">
          <div class="icon">
            <div class="pic" style="opacity:0.5">
              <DollarSignIcon size="1.5x" />
            </div>
          </div>
          <div class="text">
            <div class="placeholder">&nbsp;</div>
          </div>
        </div> -->
      </div>
      <div class="contacts">
        <div class="placeholder">&nbsp;</div>
      </div>
    </div>
  </div>
{/if}
