<script>
  import Datepicker from 'svelte-calendar';
  export let checked_today;
  export let checked_day;
  export let tab;
  let dateChosen = false;
  const initialPickText = 'Pick a date';
  const weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thusday',
    'Friday',
    'Saturday',
  ];
  let formattedSelected = false;
  const today = (() => {
    let d = new Date();
    d.setHours(0, 0, 0, 0);
    return d;
  })();

  function inputDateChange(ev) {
    let date = ev.target.value;
    if (date && date.split('/').length === 3 && date.split('/')[2].length > 1) {
      dateChosen = true;
      formattedSelected = date;
    }
  }

  $: selectedDate =
    formattedSelected && dateChosen
      ? new Date(formattedSelected).getTime()
      : false;
  $: checked_day = selectedDate ? new Date(selectedDate).getDay() + 1 : false;
  $: {
    if (selectedDate && selectedDate === today.getTime()) {
      checked_today = true;
      tab = 'just_show_up';
    } else {
      checked_today = false;
    }
  }

  function outputDate() {
    if (selectedDate === today.getTime()) {
      return 'Today';
    } else {
      return (
        weekdays[new Date(selectedDate).getDay()] + ' ' + formattedSelected
      );
    }
  }
  $: outputDateVar =
    formattedSelected && dateChosen ? outputDate() : initialPickText;

</script>

<div class="datesection">
  <label for="datepicker_input" class="datetitle">Date</label>
  <input
    id="datepicker_input"
    placeholder="mm/dd/yy"
    value={dateChosen && checked_day ? formattedSelected : ''}
    on:keyup={inputDateChange}
    on:change={inputDateChange}
    type="text"
  />
  <Datepicker
    bind:dateChosen
    format={'#{m}/#{d}/#{Y}'}
    bind:formattedSelected
    start={today}
    end={new Date(new Date().setFullYear(new Date().getFullYear() + 2))}
  >
  <div class="datevalue">
    <div>
      {#if checked_day}{outputDateVar}{:else}{initialPickText}
      <svg version="1.1"
	 width="20px" height="20px" viewBox="0 0 489 489" style="fill: #215467; enable-background:new 0 0 488.152 488.152;"
	 xml:space="preserve">
<g>
	<g>
		<path d="M177.854,269.311c0-6.115-4.96-11.069-11.08-11.069h-38.665c-6.113,0-11.074,4.954-11.074,11.069v38.66
			c0,6.123,4.961,11.079,11.074,11.079h38.665c6.12,0,11.08-4.956,11.08-11.079V269.311L177.854,269.311z"/>
		<path d="M274.483,269.311c0-6.115-4.961-11.069-11.069-11.069h-38.67c-6.113,0-11.074,4.954-11.074,11.069v38.66
			c0,6.123,4.961,11.079,11.074,11.079h38.67c6.108,0,11.069-4.956,11.069-11.079V269.311z"/>
		<path d="M371.117,269.311c0-6.115-4.961-11.069-11.074-11.069h-38.665c-6.12,0-11.08,4.954-11.08,11.069v38.66
			c0,6.123,4.96,11.079,11.08,11.079h38.665c6.113,0,11.074-4.956,11.074-11.079V269.311z"/>
		<path d="M177.854,365.95c0-6.125-4.96-11.075-11.08-11.075h-38.665c-6.113,0-11.074,4.95-11.074,11.075v38.653
			c0,6.119,4.961,11.074,11.074,11.074h38.665c6.12,0,11.08-4.956,11.08-11.074V365.95L177.854,365.95z"/>
		<path d="M274.483,365.95c0-6.125-4.961-11.075-11.069-11.075h-38.67c-6.113,0-11.074,4.95-11.074,11.075v38.653
			c0,6.119,4.961,11.074,11.074,11.074h38.67c6.108,0,11.069-4.956,11.069-11.074V365.95z"/>
		<path d="M371.117,365.95c0-6.125-4.961-11.075-11.069-11.075h-38.67c-6.12,0-11.08,4.95-11.08,11.075v38.653
			c0,6.119,4.96,11.074,11.08,11.074h38.67c6.108,0,11.069-4.956,11.069-11.074V365.95L371.117,365.95z"/>
		<path d="M440.254,54.354v59.05c0,26.69-21.652,48.198-48.338,48.198h-30.493c-26.688,0-48.627-21.508-48.627-48.198V54.142
			h-137.44v59.262c0,26.69-21.938,48.198-48.622,48.198H96.235c-26.685,0-48.336-21.508-48.336-48.198v-59.05
			C24.576,55.057,5.411,74.356,5.411,98.077v346.061c0,24.167,19.588,44.015,43.755,44.015h389.82
			c24.131,0,43.755-19.889,43.755-44.015V98.077C482.741,74.356,463.577,55.057,440.254,54.354z M426.091,422.588
			c0,10.444-8.468,18.917-18.916,18.917H80.144c-10.448,0-18.916-8.473-18.916-18.917V243.835c0-10.448,8.467-18.921,18.916-18.921
			h327.03c10.448,0,18.916,8.473,18.916,18.921L426.091,422.588L426.091,422.588z"/>
		<path d="M96.128,129.945h30.162c9.155,0,16.578-7.412,16.578-16.567V16.573C142.868,7.417,135.445,0,126.29,0H96.128
			C86.972,0,79.55,7.417,79.55,16.573v96.805C79.55,122.533,86.972,129.945,96.128,129.945z"/>
		<path d="M361.035,129.945h30.162c9.149,0,16.572-7.412,16.572-16.567V16.573C407.77,7.417,400.347,0,391.197,0h-30.162
			c-9.154,0-16.577,7.417-16.577,16.573v96.805C344.458,122.533,351.881,129.945,361.035,129.945z"/>
	</g>
</g>
</svg>
      {/if}
    </div>
  </div>
</Datepicker>
</div>

<style>
  input {
    font-size: 1.1em;
    display: flex;
    margin: 10px auto;
    width: 100px;
    border: 1px solid #2e748d;
    padding: 5px 10px;
  }
  .datesection {
    border: 2px solid #215467;
    /* overflow: hidden; */
    width: auto;
    min-width: 150px;
    margin: 10px 20px;
    border-radius: 20px;
    /* background: #2e748d;
    background: -moz-linear-gradient(top, #2e748d 0%, #215467 100%);
    background: -webkit-linear-gradient(top, #2e748d 0%, #215467 100%);
    background: linear, to bottom, #2e748d 0%, #215467 100%; */
    /* transition: background .3s ease-in; */
  }
  .datesection:hover {
    /* background: #2e748d; */
  }
  .datetitle {
    display: block;
    padding: 10px 20px;
    color: white;
    font-weight: bold;
    background: #2e748d;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background: -moz-linear-gradient(top, #2e748d 0%, #215467 100%);
    background: -webkit-linear-gradient(top, #2e748d 0%, #215467 100%);
    background: linear, to bottom, #2e748d 0%, #215467 100%;
  }
  .datevalue {
    padding: 10px 20px;
    color: #215467;
    font-weight: bold;
    cursor: pointer;
    position: relative;
  }
</style>
