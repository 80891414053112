<script>
  import { CheckIcon } from 'svelte-feather-icons';
  export let title = 'Test';
  export let options = false;
  export let checked = [];
  export let scroll = false;
  export let clean = true;
  export let deselectText = 'Deselect All';
  export let onEmptyText = '';

  $: opts =
    clean === false
      ? options
      : options && options.length
      ? [[0, deselectText], ...options]
      : [];

  $: if (options && checked.length) {
    let values = options.map((el) => Number(el[0]));
    let new_checked = checked.reduce((arr, el) => {
      if (values.includes(el)) arr = [...arr, el];
      return arr;
    }, []);
    if (JSON.stringify(new_checked) !== JSON.stringify(checked)) {
      checked = new_checked;
    }
  }
  function toggleCheck(id) {
    if (id === 0) {
      checked = [];
      return;
    } else {
      return checked.includes(id)
        ? (checked = checked.filter((i) => i !== id))
        : (checked = [...checked, id]);
    }
  }
</script>

<style>
  section {
    border: 1px solid #2e748d;
    display: flex;
    flex-direction: column;
    margin: 10px;
    border-radius: 5px;
    flex-grow: 1;
  }
  .title {
    background: #2e748d;
    padding: 4px 7px;
    color: white;
    font-weight: bold;
  }
  .line {
    display: flex;
    width: 100%;
    flex-direction: row;
    cursor: pointer;
    line-height: 1.5em;
  }

  .mark {
    height: 20px;
    width: 20px;
    margin: 0 5px;
  }
  .line.checked {
    background: #64b2ce;
  }
  .line:hover,
  .line.checked:hover {
    background: #afddee;
  }
  .name {
    margin-right: 15px;
  }
  .name.inactive {
    color: #999;
  }
  .scroll {
    height: 80px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .empty {
    text-align: center;
    padding: 15px 0;
  }
</style>

{#if opts}
  <section>
    <div class="title">{title}</div>
    <div class:scroll>
      {#each opts as o}
        <div
          class="line"
          on:mousedown={(e) => toggleCheck(parseInt(o[0]))}
          class:checked={checked.includes(parseInt(o[0]))}>
          <div class="mark">
            {#if checked.includes(parseInt(o[0]))}
              <CheckIcon />
            {/if}
          </div>
          <div
            class="name"
            class:inactive={parseInt(o[0]) === 0 && checked.length === 0}>
            {o[1]}
          </div>
        </div>
      {/each}
      {#if !options || options.length === 0}
        <div class="empty">{onEmptyText}</div>
      {/if}
    </div>
  </section>
{/if}
