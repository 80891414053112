<script>
  export let value;
</script>

<style>
  label {
    margin: 10px 20px;
    display: flex;
    flex-direction: column;
  }
  .text {
    padding: 3px 5px;
    color: #246177;
    font-weight: bold;
    display: block;
  }
  .input {
  }
  input {
    border: 2px solid #215467;
    border-radius: 20px;
    padding: 7px 15px;
    font-size: 1.1em;
    background: rgba(237, 240, 235, 0.822);
    width: 100%;
    box-sizing: border-box;
  }
  input:focus {
    background: white;
  }
</style>

<label>
  <div class="text">Search by Name:</div>
  <input placeholder="start typing" type="text" bind:value />
</label>
