export default function filter({ providers, checked, ages }) {
	let all = Object.values(
		providers
	).reduce(
		(
			a,
			{
				order,
				name,
				medical_only,
				medicaid_eligibility,
				veterans_eligibility,
				paratransit_eligibility,
				service_region,
				service_cities,
				vehicle_type,
				accessibility,
				id,
				call_ahead,
				minimum_age,
				service_days,
				rating
			}
		) => {
			let n = {
				order,
				id,
				rating,
				name,
				call_ahead,
				minimum_age,
				medical_only,
				medicaid_eligibility,
				veterans_eligibility,
				paratransit_eligibility,
				accessibility: accessibility.split(',').map((el) => parseInt(el)),
				vehicle_type: vehicle_type.split(',').map((el) => parseInt(el)),
				service_cities: service_cities ? service_cities.split(',').map((el) => parseInt(el)) : [],
				service_region: service_region.split(',').map((el) => parseInt(el)),
				service_days: service_days.split(',').map((el) => parseInt(el))
			};
			return [ ...a, n ];
		},
		[]
	);
	// making filtering
	all = all
		.filter((i) => (checked.term === '' ? true : i.name.toLowerCase().indexOf(checked.term.toLowerCase()) !== -1))
		.filter((i) => (checked.medical_trip ? true : i.medical_only == 0))
		.filter((i) => (checked.paratransit_eligibility ? true : i.paratransit_eligibility == 0))
		.filter((i) => (checked.veterans_eligibility ? true : i.veterans_eligibility == 0))
		.filter((i) => (checked.medicaid_eligibility ? true : i.medicaid_eligibility == 0))
		.filter(
			(i) =>
				checked.serviceRegionOptions.length === 0
					? true
					: checked.serviceRegionOptions.reduce((valid, i2) => {
							return valid || i.service_region.includes(i2);
						}, false)
		)
		.filter(
			(i) =>
				checked.serviceCitiesOptions.length === 0
					? true
					: checked.serviceCitiesOptions.reduce((valid, i2) => {
							return valid || i.service_cities.includes(i2);
						}, false)
		)
		.filter(
			(i) =>
				checked.vehicleTypeOptions.length === 0
					? true
					: checked.vehicleTypeOptions.reduce((valid, i2) => {
							return valid || i.vehicle_type.includes(i2);
						}, false)
		)
		.filter(
			(i) =>
				checked.accesibilityOptions.length === 0
					? true
					: checked.accesibilityOptions.reduce((valid, i2) => {
							return valid || i.accessibility.includes(i2);
						}, false)
		)
		.filter((i) => (checked.day === false ? true : i.service_days.includes(checked.day)))
		.filter((i) => {
			if (checked.tab === 'all') return true;
			if (checked.tab === 'call_ahead' && i.call_ahead) return true;
			if (checked.tab === 'just_show_up' && i.call_ahead === 0) return true;
			return false;
		})
		.filter(
			(i) =>
				checked.age.length === 0
					? true
					: checked.age.reduce((valid, i2) => {
						console.log(ages[i2][2][1]);
							return (
								valid ||
								(i.minimum_age !== null &&
									ages[i2][2][1] >= i.minimum_age
									// && i.minimum_age <= ages[i2][2][1]
									)
							);
						}, false)
		)
		.sort((a, b) => {
			if (a.order - b.order !== 0) {
				return a.order - b.order;
			}
			if (b.rating - a.rating !== 0) {
				return b.rating - a.rating;
			}
			const aName = a.name.toUpperCase();
			const bName = b.name.toUpperCase();
			if (aName < bName) {
				return -1;
			}
			if (aName > bName) {
				return 1;
			}
			return 0;
		});
	// service_region.console.log(checked);
	// console.log(all);
	// console.log(all);
	// return filtered;
	return all.map((i) => i.id);
}
