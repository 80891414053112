<script>
  import { HeartIcon } from 'svelte-feather-icons';
  import LoadingDots from './LoadingDots.svelte';
  export let id;
  export let number;
  let rateResult = false;
  let loadingRating = false;
  let already = false;
  if (localStorage.getItem('rated' + id)) {
    already = true;
  }

  $: showRating = rateResult ? rateResult : number ? number : '';

  async function doRate() {
    let action = 'rate';
    if (already) action = 'unrate';
    loadingRating = true;
    const res = await fetch('https://' + __API_URL__ + `/json/${action}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        provider: id,
      }),
    });

    const json = await res.json();

    rateResult = JSON.stringify(json);
    if (rateResult) {
      already = !already;
      if (already) localStorage.setItem('rated' + id, 1);
      else localStorage.removeItem('rated' + id);
    }
    loadingRating = false;
  }
</script>

<style>
  .rating {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 70px;
    /* background: pink; */
    align-items: center;
    margin: 0 0 10px 10px;
  }
  /* .rating.already {
    cursor: default;
  } */
  .heart {
    color: #666;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .text {
    color: #666;
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }
  .heart.rated {
    color: rgb(214, 94, 94);
  }
  .rating:hover .heart {
    color: #d42d2d;
  }
  .rating:hover .text {
    color: rgb(212, 45, 45);
    opacity: 1;
  }
  .rating.already:hover .heart {
    color: rgb(214, 94, 94);
  }
  .rating.already:hover .text {
    color: #666;
  }
</style>

<div
  class="rating"
  class:already
  class:loading={loadingRating}
  on:mousedown={doRate}>
  <div class="heart" class:rated={number || rateResult}>
    <span><HeartIcon size="30" /></span>
    {#if showRating}<span style="padding-left: 5px;">{showRating}</span>{/if}
  </div>
  <div class="text">
    {#if loadingRating}
      <LoadingDots />
    {:else}{already ? 'Unlike' : 'Like'}{/if}
  </div>
</div>
