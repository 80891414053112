<script>
  export let options = false;
  export let ages = false;
  export let checked;
  export let initialChecked;

  let lines = [];
  const filterAppliedText = [
    ['term', 'Filtered by Name:'],
    ['day', 'Filtered by Day:'],
    ['serviceRegionOptions', 'Filtered by Region:'],
    ['serviceCitiesOptions', 'Filtered by Origin City:'],
    ['accesibilityOptions', 'Filtered by Accessibility:'],
    ['age', 'Filtered by Age:'],
    ['medical_trip', 'Medical Trip:'],
    ['paratransit_eligibility', 'Access Eligible:'],
    ['veterans_eligibility', 'Veterans Benefits:'],
    ['medicaid_eligibility', 'Eligible for Medicaid:'],
  ];
  $: if (options && ages) {
    lines = filterAppliedText.reduce((arr, f) => {
      if (
        JSON.stringify(checked[f[0]]) !== JSON.stringify(initialChecked[f[0]])
      ) {
        let line = [f[1], ''];
        if (f[0] === 'serviceCitiesOptions') {
          line[1] = checked['serviceCitiesOptions']
            .map((_) => options['serviceCitiesOptions'][Number(_)].name)
            .join(', ');
        } else if (typeof options[f[0]] !== 'undefined') {
          line[1] = checked[f[0]]
            .map((_) => options[f[0]][Number(_)])
            .join(', ');
        } else if (f[0] === 'day') {
          line[1] = options['serviceDaysOptions'][Number(checked['day'])];
        } else if (f[0] === 'age') {
          //   line[1] = ages.reduce(
          //     (str, a) =>
          //       checked['age'].indexOf(a[0]) ? `${a[0]}, ${str}` : str,
          //     ''
          //   );
          line[1] = checked[f[0]]
            .map((_) =>
              ages.reduce((str, a) => (a[0] === Number(_) ? a[1] : str), '')
            )
            .join(', ');
        } else {
          line[1] = checked[f[0]] ? 'Yes' : 'No';
        }
        return [...arr, line];
      } else {
        return arr;
      }
    }, []);
  }
</script>

<style>
  .all {
    width: 100%;
    padding: 10px;
    background: #f0d4b2;
    margin: 0 10px 0 0;
    border-radius: 5px;
    box-sizing: border-box;
  }
  @media (min-width: 768px) {
    .all {
      max-width: 40vw;
    }
  }
  span {
    font-weight: normal;
  }
  .line {
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>

{#if lines.length}
  <div style="padding-right: 15px;">
    <div class="all">
      {#each lines as l}
        <div class="line"><strong>{l[0]}</strong> <span>{l[1]}</span></div>
      {/each}
    </div>
  </div>
{/if}
