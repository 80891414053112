<script>
  import Router from 'svelte-spa-router';
  import { push } from 'svelte-spa-router';
  import Home from './routes/Home.svelte';
  import Providers from './routes/Providers.svelte';
  import Provider from './routes/Provider.svelte';
  //   import NotFound from './routes/NotFound.svelte';

  const routes = {
    // Exact path
    '/': Home,
    // // Using named parameters, with last being optional
    // '/author/:first/:last?': Author,
    '/providers/:filter?': Providers,
    '/provider/:alias': Provider,

    // Catch-all
    // This is optional, but if present it must be the last
    // '*': NotFound,
  };
  if (typeof providersRouter !== 'undefined' && providersRouter) push('/providers');
</script>

<style>
  .router {
    font-family: sans-serif;
    --avhstBrandColor: #2e748d;
    --avhstBrandColorLight: #bce2e5;
    line-height: normal;
  }
  @-webkit-keyframes -global-placeholderAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @-moz-keyframes -global-placeholderAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes -global-placeholderAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
</style>

<div class="router">
  <Router {routes} />
</div>
