<script>
  export let title = false;
  export let checked = false;
  export let outlined = true;
</script>

<style>
  .wrapper {
    width: 130px;
  }
  .title {
    padding: 3px 5px;
    color: #246177;
    font-weight: bold;
  }
  .switch {
    display: flex;
    /* width: 100%; */
    position: relative;
    border: 4px solid white;
    border-radius: 20px;
    height: 30px;
    overflow: hidden;
    background: white;
    cursor: pointer;
    margin: 5px;
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
  }
  .switch.outlined {
    box-shadow: 0 0 0 3px #999;
  }
  .label {
    flex-grow: 1;
    z-index: 1;
    color: #246177;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.3s ease-out;
  }
  .label.checked {
    color: white;
  }
  .label span {
    padding: 5px;
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
  }
  .no {
    background: transparent;
  }
  .yes {
    background: transparent;
  }
  .pin {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background: #2e748d;
    background: -moz-linear-gradient(top, #2e748d 0%, #215467 100%);
    background: -webkit-linear-gradient(top, #2e748d 0%, #215467 100%);
    background: linear, to bottom, #2e748d 0%, #215467 100%;
    border-radius: 20px;
    z-index: 0;
    transform: translate(0);
    transition: transform 0.3s ease-out;
  }
  .pin.checked {
    transform: translate(100%, 0);
  }
</style>

<div class="wrapper">
  {#if title}
    <div class="title">{title}</div>
  {/if}
  <div
    class="switch"
    class:outlined
    on:mousedown={(e) => {
      checked = !checked;
    }}>
    <div class="pin" class:checked />
    <div class="no label" class:checked={!checked}><span>No</span></div>
    <div class="yes label" class:checked><span>Yes</span></div>
  </div>
</div>
