<style>
  button {
    display: inline-block;
    border: none;
    padding: 1rem 2rem;
    margin: 0;
    text-decoration: none;
    border-radius: 10px;
    background: #2e748d;
    background: -moz-linear-gradient(top, #2e748d 0%, #215467 100%);
    background: -webkit-linear-gradient(top, #2e748d 0%, #215467 100%);
    background: linear, to bottom, #2e748d 0%, #215467 100%;
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  button:hover,
  button:focus {
    background: #2e748d;
  }

  button:focus {
    outline: 1px solid #fff;
    outline-offset: -4px;
  }
</style>

<button on:mousedown><slot /></button>
