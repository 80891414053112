<script>
  import { CrosshairIcon, PhoneCallIcon, StarIcon } from 'svelte-feather-icons';

  export let tab = 'all';
</script>

<style>
  .tabs {
    margin: 10px 0;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    border-bottom: 3px solid var(--avhstBrandColor);
    line-height: normal;
  }
  .tabs div {
    cursor: pointer;
    font-weight: bold;
    font-size: 1em;
    color: var(--avhstBrandColor);
    padding: 10px 20px;
    border: 2px solid var(--avhstBrandColor);
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom: none;
    transition: background-color 0.2s ease-out;
    margin-right: 5px;
    display: flex;
    align-items: center;
    line-height: normal;
  }
  .tabs div span {
      margin: 0 3px;
  }
  @media (min-width: 600px) {
    .tabs div {
      font-size: 1.2em;
    }
  }
  .tabs div:hover {
    background-color: var(--avhstBrandColorLight);
  }
  .tabs div.selected {
    background-color: var(--avhstBrandColor);
    color: white;
  }
</style>

<div class="tabs">
  <div class:selected={tab === 'all'} on:click={() => (tab = 'all')}>
    <span><StarIcon size="23" /></span>
    <span>All</span>
  </div>
  <div
    class:selected={tab === 'call_ahead'}
    on:click={() => (tab = 'call_ahead')}>
    <span><PhoneCallIcon size="23" /></span>
    <span> Prescheduled</span>
  </div>
  <div
    class:selected={tab === 'just_show_up'}
    on:click={() => (tab = 'just_show_up')}>
    <span><CrosshairIcon size="23" /></span>
    <span> Just Show Up</span>
  </div>
</div>
