<script>
  import { ChevronsRightIcon } from 'svelte-feather-icons';
  import { fade } from 'svelte/transition';
  import Button from '../components/Button.svelte';
  import Rating from '../components/Rating.svelte';
  import Image from '../components/Image.svelte';
  import MiniMap from '../components/MiniMap.svelte';
  export let params = false;
  let item = false;
  let options = false;
  let buttontext = localStorage.getItem('checked')
    ? '&larr; Results'
    : '&larr; Providers';

  fetch('https://' + __API_URL__ + '/json/options')
    .then((response) => response.json())
    .then((data) => (options = data));

  $: if (params) {
    fetch('https://' + __API_URL__ + '/json/providers?alias=' + params.alias)
      .then((response) => response.json())
      .then((data) => (item = data));
  }
</script>

<style>
  a {
    color: #de7e0b;
    text-decoration: none;
    transition: color 0.2s;
  }
  a:hover {
    color: #ca6f00;
  }
  .item {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
  }
  .columns {
  }
  .left {
    padding-right: 20px;
  }
  .right {
  }
  .topline {
    text-align: center;
  }
  @media (min-width: 768px) {
    .columns {
      display: flex;
    }
    .left {
      flex-grow: 1;
      padding-right: 20px;
    }
    .right {
      min-width: 200px;
    }
    .topline {
      text-align: left;
    }
  }

  .placeholder {
    width: 100%;
    padding: 5px 0;
    background: linear-gradient(270deg, #d2d4d4, #a6aaaa);
    background-size: 400% 400%;

    -webkit-animation: placeholderAnimation 2s ease infinite;
    -moz-animation: placeholderAnimation 2s ease infinite;
    animation: placeholderAnimation 2s ease infinite;
  }
  .summary.placeholder {
    height: 200px;
  }
  .summary.placeholder {
    height: 400px;
  }
  .image {
        width: 100%;
 height: auto;
  }
  h1,
  .image {
    margin-top: 20px;
  }
  h1 {
    color: #2e748d;
  }
  .summary,
  .line {
    font-size: 1em;
    line-height: 1.56em;
  }
  .gray {
    background: #ddd;
    height: 350px;
  }
  .contacts {
    font-size: 1.5em;
    line-height: 1.6em;
  }
  .link {
  }
  .label {
    font-weight: bold;
    padding-bottom: 5px;
  }
  .line {
    padding: 15px 10px;
  }
  .line:nth-child(odd) {
    background: rgba(237, 240, 235, 0.822);
  }
  @media (min-width: 768px) {
    .line {
      display: flex;
      align-items: center;
    }
    .label {
      min-width: 25%;
      max-width: 25%;
      padding-right: 10px;
      padding-bottom: 0;
    }
    .text {
      flex-grow: 1;
    }
  }
  .ratingwrapper {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .summary :global(a) {
    color: #de7e0b;
    text-decoration: none;
    transition: color 0.2s;
  }
  .summary :global(a:hover) {
    color: #ca6f00;
  }
</style>

<div class="item" in:fade>
  <div class="topline">
    <a
      href="#/providers/stored"><Button>
        {@html buttontext}
      </Button></a>
  </div>

  {#if item && options}
    <div class="provider">
      <div class="columns">
        <div class="left">
          <h1>{item.name}</h1>
          <div class="summary">
            {@html item.summary}
          </div>
          <div class="line">
            <div class="label">Phone:</div>
            <div class="text contacts">
              {@html item.contacts.replace(/\n/g, '<br />')}
            </div>
          </div>
          <div class="line">
            <div class="label">Website:</div>
            <div class="text">
              <a href={item.url} target="_blank">{item.website_title}</a>
            </div>
          </div>
          <div class="line">
            <div class="label">Type of Service:</div>
            <div class="text">
              {options.typeOfServiceOptions[item.type_of_service]}
              <br /><strong>{item.call_ahead ? 'Prescheduled Service' : '"Just Show Up" Service'}</strong>
            </div>
          </div>
          <div class="line">
            <div class="label">Vehicle Type:</div>
            <div class="text">
              {item.vehicle_type
                .split(',')
                .map((i) => options.vehicleTypeOptions[parseInt(i)])
                .join(', ')}
            </div>
          </div>
          <!-- <div class="line">
            <div class="label">Service Area:</div>
            <div class="text">
              {@html item.service_area.replace(/\n/g, '<br />')}
            </div>
          </div> -->
          {#if item.service_cities}
            <div class="line">
              <div class="label">Origin City:</div>
              <div class="text">
                {item.service_cities
                  .split(',')
                  .map((i) => options.serviceCitiesOptions[parseInt(i)].name)
                  .join(', ')}
              </div>
            </div>
          {/if}
          <div class="line">
            <div class="label">Service Days:</div>
            <div class="text">
              {item.service_days
                .split(',')
                .map((i) => options.serviceDaysOptions[parseInt(i)])
                .join(', ')}
            </div>
          </div>
          <div class="line">
            <div class="label">Service Hours:</div>
            <div class="text">
              {@html item.service_hours.replace(/\n/g, '<br />')}
            </div>
          </div>
          {#if item.advance_notice_notes}
            <div class="line">
              <div class="label">Advance Notice Notes:</div>
              <div class="text">
                {@html item.advance_notice_notes.replace(/\n/g, '<br />')}
              </div>
            </div>
          {/if}
          <div class="line">
            <div class="label">Cost:</div>
            <div class="text">
              {#if item.cost_adult}
                <div>
                  <ChevronsRightIcon size="12" />
                  {item.cost_adult}
                  <strong>for Adults</strong>
                </div>
              {/if}
              {#if item.cost_senior}
                <div>
                  <ChevronsRightIcon size="12" />
                  {item.cost_senior}
                  <strong>for Seniors and people w/ disabilities</strong>
                </div>
              {/if}
              {#if item.cost_youth}
                <div>
                  <ChevronsRightIcon size="12" />
                  {item.cost_youth}
                  <strong>for Youth (ages 6-18)</strong>
                </div>
              {/if}
            </div>
          </div>
          {#if item.available_discounts}
            <div class="line">
              <div class="label">Full Fare Information:</div>
              <div class="text">
                {@html item.available_discounts.replace(/\n/g, '<br />')}
              </div>
            </div>
          {/if}
          <div class="line">
            <div class="label">Paratransit Eligibility Required:</div>
            <div class="text">
              {item.paratransit_eligibility ? 'Yes' : 'No'}
            </div>
          </div>
          <div class="line">
            <div class="label">Accessibility</div>
            <div class="text">
              {item.accessibility
                .split(',')
                .map((i) => options.accesibilityOptions[parseInt(i)])
                .join(', ')}
            </div>
          </div>
          <div class="line">
            <div class="label">Veterans Eligibility</div>
            <div class="text">{item.veterans_eligibility ? 'Yes' : 'No'}</div>
          </div>
          <div class="line">
            <div class="label">Medicaid Eligibility</div>
            <div class="text">{item.medicaid_eligibility ? 'Yes' : 'No'}</div>
          </div>
          <div class="line">
            <div class="label">Medical Only</div>
            <div class="text">{item.medical_only ? 'Yes' : 'No'}</div>
          </div>
          {#if item.minimum_age}
            <div class="line">
              <div class="label">Minimum Age</div>
              <div class="text">{item.minimum_age}</div>
            </div>
          {/if}
        </div>
        <div class="right">
          <div class="image">
            {#if item.image}
              <Image
                src={'https://' + __API_URL__ + '/images/provider/' + item.image}
                name={item.name} />
            {:else if item.service_region}
              <MiniMap
                regions={item.service_region
                  .split(',')
                  .map((s) => Number(s))} />
            {:else}&nbsp;{/if}
          </div>
          <div class="ratingwrapper">
            <Rating id={item.id} number={item.rating} />
          </div>
        </div>
      </div>
    </div>
  {:else if item === null}
    <h3>Not Found</h3>
  {:else}
    <div class="provider">
      <div class="columns">
        <div class="left">
          <h1 class="placeholder">&nbsp;</h1>
          <div class="summary placeholder">&nbsp;</div>
        </div>
        <div class="right">
          <div class="image placeholder">&nbsp;</div>
        </div>
      </div>
    </div>
  {/if}
</div>
