<script>
    import { ChevronDownIcon } from 'svelte-feather-icons';
  export let selected = false;
  export let options = [];
  export let down = false;
  export let title = false;

  $: {
    // console.log(options);
    // console.log(selected);
  }
</script>

<style>
  .dropdown {
    cursor: pointer;
    display: inline-block;
    position: relative;
    width: 100%;
    background: white;
    font-size: 1em;
    color: #00709c;
    
  }
  .options {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 0 2px 4px rgb(100, 100, 100);
  }
  .options.down {
    display: block;
  }
  .options div {
    background: white;
    padding: 3px 10px;
    border-top: 1px solid #e1d8c6;
  }
  .options div:hover {
    background: #e1d8c6;
  }
  .result {
    padding: 8px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .result span {
    /* line-height: 24px; */
  }
</style>

{#if title}
  <div class="title">{title}</div>
{/if}
{#if options}
  <div class="dropdown">
    <div class="result" on:click={() => (down = !down)}>
      <span>{options.reduce((str, el) => (str ? str : Number(el[0]) === selected && el[1]), false)}</span>
      <span><ChevronDownIcon size="20"/></span>
    </div>
    <div class="options" class:down>
      {#each options as opt}
        <div
          on:click={() => {
            selected = Number(opt[0]);
            down = false;
          }}>
          {opt[1]}
        </div>
      {/each}
    </div>
  </div>
{/if}
