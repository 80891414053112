<script>
  import { CrosshairIcon } from 'svelte-feather-icons';
  import collection from '../geojson.json';
  export let region;
  export let regionHover;
  let mapLayers = [];
  let map;
  let Lgeojson;
  export let geoOn = false;
  export let geoSearching = false;
  let geoMarker;
  let currentLocation;

  $: {
    mapLayers.forEach((l) => {
      if (l.feature.properties.id === region) {
        selectMapRegion(l);
      }
    });
  }
  $: {
    mapLayers.forEach((l) => {
      if (l.feature.properties.id !== region) {
        if (l.feature.properties.id === regionHover) {
          l.setStyle({
            fillColor: '#2e748d',
            fillOpacity: 0.5,
          });
        } else {
          Lgeojson.resetStyle(l);
        }
      }
    });
  }

  function createMap(container) {
    let m = L.map(container, {
      minZoom: 5,
      maxZoom: 13,
      scrollWheelZoom: false,
    }).setView([47.52091047852614, -122.45223999023436], 9);
    L.tileLayer(
      'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
      {
        attribution: `&copy;<a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>,
        &copy;<a href="https://carto.com/attributions" target="_blank">CARTO</a>`,
        subdomains: 'abcd',
        maxZoom: 13,
      }
    ).addTo(m);
    Lgeojson = L.geoJson(collection, {
      style: {
        weight: 2,
        color: '#DE7E0A',
      },
      onEachFeature: onEachFeature,
    }).addTo(m);

    function onEachFeature(feature, layer) {
      layer.on('mouseover', (e) => hoverMapRegion(e.target));
      layer.on('mouseout', (e) => hoveroutMapRegion(e.target));
      layer.on('mousedown', (e) => selectMapRegion(e.target));
      mapLayers.push(layer);
    }

    return m;
  }
  function hoverMapRegion(el) {
    if (region !== el.feature.properties.id) {
      regionHover = el.feature.properties.id;
    }
  }
  function hoveroutMapRegion(el) {
    if (region !== el.feature.properties.id) {
      regionHover = 0;
    }
  }
  function selectMapRegion(el) {
    mapLayers.map((l) => Lgeojson.resetStyle(l));
    el.setStyle({
      fillColor: '#102c37',
      fillOpacity: 0.7,
    });
    region = el.feature.properties.id;
  }

  function toggleGeoLocation() {
    if (typeof L === 'undefined' || !L) return;

    if (geoOn) {
      // Was on and now we're going to Off
      geoOn = false;
      geoSearching = false;
      map.setView([47.52091047852614, -122.45223999023436], 9);
      if (currentLocation) {
        currentLocation.stop();
      }
      if (geoMarker) {
        geoMarker.forEach((mark) => {
          map.removeLayer(mark);
        });
      }
    } else {
      // Turning On (waiting for "locationfound" event is done)
      geoSearching = true;
      currentLocation = map.locate({ watch: false, setView: true, maxZoom: 9 });
    }
  }
  $: if (geoSearching) {
    if (typeof L === 'undefined' || !L) {
    } else {
      toggleGeoLocation();
    }
  }
  function isMarkerInsidePolygon(latlng, poly) {
    var polyPoints = poly.getLatLngs()[0];
    var x = latlng.lat,
      y = latlng.lng;
    var inside = false;
    for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
      var xi = polyPoints[i].lat,
        yi = polyPoints[i].lng;
      var xj = polyPoints[j].lat,
        yj = polyPoints[j].lng;

      var intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  }
  function mapAction(container) {
    if (typeof L === 'undefined' || !L) {
      return { destroy: () => {} };
    } else {
      map = createMap(container);
      map.on('locationfound', (e) => {
        geoSearching = false;
        geoOn = true;
        var radius = e.accuracy;

        geoMarker = [
          L.marker(e.latlng)
            .addTo(map)
            .bindPopup('You are within ' + radius + ' meters from this point'),
          // .openPopup(),
          L.circle(e.latlng, {
            radius,
            stroke: false,
            fillOpacity: 0.3,
            fillColor: '#de7e0a',
          }).addTo(map),
        ];
        mapLayers.forEach((ml) => {
          if (isMarkerInsidePolygon(e.latlng, ml)) {
            region = ml.feature.properties.id;
            selectMapRegion(ml);
          }
        });
      });
      map.on('locationerror', () => {
        geoSearching = false;
        console.log('error geo');
        alert(
          'Sorry, your browser is not sharing your location. \r\rTry refreshing the page and clicking Allow when your browser asks you to share your location.  If you continue to have issues, try another, more modern browser.'
        );
      });
      return {
        destroy: () => {
          map.remove();
        },
      };
    }
  }
</script>

<style>
  .map {
    min-height: 800px;
    width: 100%;
    display: none;
  }

  .geobutton {
    position: absolute;
    top: 100px;
    left: 18px;
    display: none;
    padding: 5px;
    justify-content: center;
    cursor: pointer;
    background: white;
    color: #333;
    border-radius: 2px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    align-items: center;
    /* transition: background-color 0.2s ease-out; */
  }
  .geobutton:hover {
    background: #f5a13b;
  }
  .geobutton.geoOn {
    background: #de7e0a;
  }
  .geobutton.geoSearching {
    background: linear-gradient(270deg, rgb(255, 255, 255), #de7e0a);
    background-size: 400% 400%;

    -webkit-animation: placeholderAnimation 1s ease infinite;
    -moz-animation: placeholderAnimation 1s ease infinite;
    animation: placeholderAnimation 1s ease infinite;
  }
  :global(.myGeoIcon) {
    height: 20px;
    width: 20px;
    border: 3px solid #de7e0a;
    border-radius: 100%;
  }
  @media (min-width: 600px) {
    .map {
      display: block;
    }
    .geobutton {
      display: flex;
    }
  }
</style>

<div style="display: none;">
  <div class="myGeoIcon" />
</div>
<div class="map" use:mapAction />
<div
  class="geobutton"
  class:geoSearching
  class:geoOn
  title="Get Your Geo Location"
  on:click={toggleGeoLocation}>
  <CrosshairIcon size="16" />
</div>
