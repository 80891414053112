<script>
  import MultiSelect from './MultiSelect.svelte';
  import DateBlock from './DateBlock.svelte';
  import Input from './Input.svelte';
  import Switch from './Switch.svelte';
  export let options = false;
  export let checked = false;
  export let ages = false;

  let citiesByRegionOptions = [];

  $: if (options && checked) {
    citiesByRegionOptions = Object.entries(options.serviceCitiesOptions)
      .sort(function (a, b) {
        return a[1]['order'] - b[1]['order'];
      })
      .reduce((arr, city) => {
        if (
          checked.serviceRegionOptions.some((r) => city[1].region.includes(r))
        )
          arr.push([city[0], city[1].name]);
        return arr;
      }, []);
  }
</script>

{#if options && checked}
  <div class="filter">
    <div>
      <Input bind:value={checked.term} />
    </div>
    <div>
      <DateBlock
        bind:tab={checked.tab}
        bind:checked_day={checked.day}
        bind:checked_today={checked.today}
      />
    </div>
    <MultiSelect
      scroll
      title="Region"
      bind:checked={checked.serviceRegionOptions}
      options={Object.entries(options.serviceRegionOptions)}
    />
    <MultiSelect
      scroll
      title="Origin City"
      onEmptyText="Please, select Region(s) first"
      bind:checked={checked.serviceCitiesOptions}
      options={citiesByRegionOptions}
    />
    <!-- <MultiSelect
      scroll
      title="Vehicle Type"
      bind:checked={checked.vehicleTypeOptions}
      options={Object.entries(options.vehicleTypeOptions)} /> -->
    <MultiSelect
      scroll
      title="Accessibility"
      bind:checked={checked.accesibilityOptions}
      options={Object.entries(options.accesibilityOptions)}
    />
    <MultiSelect
      scroll
      clean={false}
      title="Age"
      bind:checked={checked.age}
      options={ages}
    />
    <div style="flex-grow: 1;">
      <Switch title="Medical Trip?" bind:checked={checked.medical_trip} />
    </div>
    <div style="flex-grow: 1;">
      <Switch
        title="ADA Paratransit Eligible?"
        bind:checked={checked.paratransit_eligibility}
      />
    </div>
    <div style="flex-grow: 1;">
      <Switch
        title="Veterans Benefits?"
        bind:checked={checked.veterans_eligibility}
      />
    </div>
    <div style="flex-grow: 1;">
      <Switch
        title="Eligible for Medicaid?"
        bind:checked={checked.medicaid_eligibility}
      />
    </div>
  </div>
{/if}

<style>
  .filter {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px 20px;
  }
</style>
