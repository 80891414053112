<script>
  // import L from 'leaflet';
  // import 'leaflet/dist/leaflet.css'
  import * as animateScroll from 'svelte-scrollto';
  import { PlusSquareIcon, UserIcon, MapPinIcon } from 'svelte-feather-icons';
  import Map from '../components/Map.svelte';
  import Switch from '../components/Switch.svelte';
  import DropDown from '../components/DropDown.svelte';
  import LoadingDots from '../components/LoadingDots.svelte';
  let geoOn = false;
  let geoSearching = false;
  let allRegions = false;
  let options = false;
  let medical = true;
  let access = 0;
  let region = 0;
  let regionHover = 0;

  fetch('https://' + __API_URL__ + '/json/options')
    .then((response) => response.json())
    .then((data) => (options = data));

  $: if (options) {
    allRegions = options.serviceRegionOptionsOrder.map((el) => [
      el,
      options.serviceRegionOptions[el],
    ]);
  }
  $: urlPart = `/medical_trip=${Number(medical)}|serviceRegionOptions=${Number(
    region
  )}|accesibilityOptions=${Number(access)}`;
</script>

<style>
  .h3 {
    margin-top: 0.2em;
    color: #102c37;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 0.5em;
    font-size: 1.125em;
  }
  .wrapper {
    position: relative;
    width: 90vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 600px) {
    .wrapper {
      position: absolute;
      top: 30px;
      left: 10%;
      width: 400px;
      margin: 0 auto 0 0;
    }
  }
  .plate {
    background: #e1d8c6;
    padding: 15px;
  }
  .other {
    background: white;
    padding: 20px 15px;
  }
  .tool a {
    line-height: 1.4;
    font-size: 1.4375em;
    font-weight: bold;
    color: #de7e0b;
    text-decoration: none;
    transition: color 0.2s;
  }
  .tool a:hover {
    color: #ca6f00;
  }
  .regions {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .region {
    cursor: pointer;
    font-weight: bold;
    width: 47%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    border-radius: 2px;
    margin-bottom: 20px;
    padding: 20px 10px;
    text-align: center;
    color: #de7e0a;
    transition: all 0.2s ease-out;
  }
  .region:hover,
  .region.hover {
    color: white;
    background: #2e748d;
  }
  .region.selected {
    color: white;
    background: #102c37;
  }
  h3 span {
    transform: translateY(2px);
    display: inline-block;
    margin-right: 5px;
  }
  .oneline {
    display: flex;
    width: 100%;
    align-items: center;
    /* justify-content: ; */
  }
  .oneline h3 {
    flex-grow: 1;
    margin-bottom: 0;
  }
  .accesses {
    margin-bottom: 20px;
  }
  a.button {
    text-decoration: none;
    display: block;
    cursor: pointer;
    width: 150px;
    text-align: center;
    margin: 25px auto 15px;
    font-size: 1.25em;
    padding: 15px 20px;
    background-color: #de7e0a;
    border-color: #ad6208;
    color: #fff;
    transition: all 0.2s ease-out;
  }
  a.button:hover {
    background-color: #ad6208;
  }
</style>

<Map bind:region bind:regionHover bind:geoOn bind:geoSearching />
<div class="wrapper">
  <div class="plate">
    <div class="h3">
      <span><MapPinIcon size="20" />
      </span>Trip Origin
    </div>
    <div class="regions">
      {#if options}
        <div
          class="region"
          class:selected={geoSearching === true}
          on:click={() => {
            geoSearching = true;
          }}>
          <div style="display:flex;justify-content:center;align-items:center;">
            <span style="margin-right:10px;"><MapPinIcon size="20" /></span>
            <span>My Location</span>
          </div>
        </div>
        {#each allRegions as r}
          <div
            class="region"
            class:hover={regionHover === r[0]}
            class:selected={region === r[0]}
            on:mouseover={() => {
              regionHover = r[0];
            }}
            on:mouseout={() => {
              regionHover = 0;
            }}
            on:click={() => {
              region = r[0];
            }}>
            {r[1]}
          </div>
        {/each}
      {:else}
        <LoadingDots />
      {/if}
    </div>
    <div class="h3">
      <span><UserIcon size="20" />
      </span>Accessibility Needs
    </div>
    <div class="accesses">
      {#if options}
        <DropDown
          options={[['0', '-Any-'], ...Object.entries(options.accesibilityOptions)]}
          bind:selected={access} />
      {/if}
    </div>
    <div class="oneline">
      <div class="h3">
        <span><PlusSquareIcon size="20" />
        </span>Medical Trip?
      </div>
      <Switch bind:checked={medical} />
    </div>

    <div>
      <a
        class="button"
        on:click={() => {
          animateScroll.scrollTo({
            element: '#providers',
            offset: -70,
            duration: 0,
          });
        }}
        href="#/providers{urlPart}">Find a Ride</a>
    </div>
  </div>

  <div class="other">
    <div class="h3">Other Tools</div>
    <div class="tool">
      <a href="https://www.findaride.org/travel-tools/medicaid-transportation">Medicaid Transportation</a>
    </div>
    <div class="tool">
      <a href="https://www.findaride.org/glossary-of-terms">Glossary of Terms</a>
    </div>
  </div>
</div>
