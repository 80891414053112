<script>
  import { fade } from 'svelte/transition';
  export let src;
  export let name;
  export let withZoom = true;
  let zoomed = false;
</script>

<style>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10000;
  }
  .close {
    position: fixed;
    z-index: 1000;
    top: 5vh;
    right: 5vw;
    color: white;
    font-size: 3em;
    cursor: pointer;
  }

  .overlay .img {
    height: 80vh;
    width: 80vw;
    margin: auto;
    /* display: flex; */
  }
  .img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  img.main {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
</style>

{#if zoomed}
  <div
    class="overlay"
    title="Click to Close"
    transition:fade={{ duration: 200 }}
    on:click={() => (zoomed = false)}>
    <div class="img"><img {src} alt={name} /></div>
  </div>
  <div
    class="close"
    transition:fade={{ duration: 200 }}
    on:click={() => (zoomed = false)}>
    &#10005;
  </div>
{/if}
<div>
  <img class="main" on:click={() => (zoomed = true)} {src} alt={name} />
</div>
