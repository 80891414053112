<style>
  .dots .dot1 {
    animation: load 1s infinite;
  }

  .dots .dot2 {
    animation: load 1s infinite;
    animation-delay: 0.2s;
  }

  .dots .dot3 {
    animation: load 1s infinite;
    animation-delay: 0.4s;
  }

  @keyframes load {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
</style>

<svg class="dots" width="66" height="29" xmlns="http://www.w3.org/2000/svg"><g
    class="dots"
    fill="#d42d2d"
    fill-rule="evenodd">
    <circle class="dot1" cx="13" cy="15" r="7" />
    <circle class="dot2" cx="32" cy="15" r="7" />
    <circle class="dot3" cx="52" cy="15" r="7" />
  </g></svg>
